/**
 * This file kicks off VITEJS tasks to minify and compile CSS and JS.
 */
// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// if (import.meta.hot) {
//     import.meta.hot.accept(() => {
//         console.log("HMR - index")
//     })
// }
const flame = String.fromCodePoint(0x1f525)

// CSS imports
import './assets/css/main.css'

// JS imports
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import mask from '@alpinejs/mask'
// import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
import validate from '@colinaut/alpinejs-plugin-simple-validate'
import './assets/js/_obaia-window-components'

Alpine.plugin(focus)
Alpine.plugin(validate)
Alpine.plugin(mask)
// Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.start()

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// if (import.meta.hot) {
//     import.meta.hot.accept(() => {
//         console.log("HMR")
//     })
// }

// * END OF FILE
console.log(`%c
            
            ` + flame + ` Made with a keyboard by ` + flame + `

 ________      ________      ________      ___      ________     
|\\   __  \\    |\\   __  \\    |\\   __  \\    |\\  \\    |\\   __  \\    
\\ \\  \\|\\  \\   \\ \\  \\|\\ /_   \\ \\  \\|\\  \\   \\ \\  \\   \\ \\  \\|\\  \\   
 \\ \\  \\\\\\  \\   \\ \\   __  \\   \\ \\   __  \\   \\ \\  \\   \\ \\   __  \\  
  \\ \\  \\\\\\  \\   \\ \\  \\|\\  \\   \\ \\  \\ \\  \\   \\ \\  \\   \\ \\  \\ \\  \\ 
   \\ \\_______\\   \\ \\_______\\   \\ \\__\\ \\__\\   \\ \\__\\   \\ \\__\\ \\__\\
    \\|_______|    \\|_______|    \\|__|\\|__|    \\|__|    \\|__|\\|__|


                            WEBDEVELOPMENT
                            https://obaia.nl

`, `font-family: monospace;`)